import { Injectable } from '@angular/core';
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateChild,
	UrlTree,
} from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate, CanActivateChild {
	constructor(private authService: AuthorizationService, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		const systemPages = ['/products', '/apikey', '/errorlog', '/retailers', '/users', '/orders/new', 'orders/deleted'];
		const url: string = state.url;

		return this.authService.user$.pipe(
			take(1),
			map(user => {
				const isLoggedIn: boolean = !!user;

				if (systemPages.includes(url) && isLoggedIn) {
					return user.system_admin;
				}

				return isLoggedIn
					? true
					: this.router.createUrlTree(['/login'], { queryParams: { returnTo: url } });
			})
		);
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		return this.canActivate(route, state);
	}
}
