import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { CognitoUser, Auth } from '@aws-amplify/auth';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive/';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
// import { CountdownSnackbarComponent } from 'src/app/modules/shared/components/countdown-snackbar/countdown-snackbar.component';

import { User } from '../models/user.model';
import { NotificationService } from './notification.service';

type AuthClass = typeof Auth

@Injectable({ providedIn: 'root' })
export class AuthorizationService implements OnDestroy {
    /*
        A Subject is used for sending (multicasting) a value to many different observers
        by calling its method "next". A BehaviorSubject is just a specific implementation
        of the Subject that receives an initial emission value, in this case "null", that is,
        the user is not logged in, and repeats the latest sent emission to every new subscription
        to this Subject.

        A Observable is returned publicly instead to avoid other parts of the system to be able to
        incorrecly call the method "next" in the Subject. That would be that any part of the system
        would be able to update the currently authenticated user and tell all of the other components about it.
        It's as if a radio listener could press a button on the radio and decide which song all listeners
        would listen to next.
    */
    private _user$ = new BehaviorSubject<User>(null);
    public user$ = this._user$.asObservable();

    private _cognitoUser: CognitoUser;
    private _auth: AuthClass;
    // private _idleSubs: Subscription[] = [];
    // private _countdownRef: MatSnackBarRef<CountdownSnackbarComponent>;

    constructor(
        private _amplifyService: AmplifyService,
        private _router: Router,
        // private _idle: Idle,
        // private _keepalive: Keepalive,
        private _notify: NotificationService,
        private _snackBar: MatSnackBar,
        private _ngZone: NgZone,
    ) {
        // Gets Auth class from Amplify via the service.
        this._auth = this._amplifyService.auth();

        // Configures the timer
        this.configureTimer();

        // Refresh token on startup
        this.refreshToken()
            .pipe(take(1))
            .subscribe((response: CognitoUser) => {
                this.createAndEmitUser(response);
                this.startTimer();
            }, console.error);
    }

    ngOnDestroy() {
        // this._idleSubs.forEach(sub => sub.unsubscribe());
    }

    /**
     * This method contacts Cognito with the user's username and password and tries to authenticate them.
     * If the attribute "challengeName" is present, the user is not yet logged in and needs to meet extra
     * login requirements, for example change a temporarily generated password. This is checked in the login
     * component which will take appropriate action. Otherwise, it will create our user object and emit it
     * to the other components in the application and start the idle / token refresh timer.
     *
     * The from() function from RxJS just converts the Promise AWS Amplify returns into an Observable
     * and take(1) makes sure to run the subscription only once, avoiding possible memory leak or the need
     * to unscribe.
     *
     * @param username
     * @param password
     */
    signIn(username: string, password: string): Observable<CognitoUser> {
        return from(this._auth.signIn(username, password)).pipe(
            take(1),
            tap((user: CognitoUser) => {
                // If there is no challenge, then user is authenticated.
                if (!user['challengeName']) {
                    this.createAndEmitUser(user);
                    this.startTimer();
                }
            })
        );
    }

    /**
     * This method signs out (logs out) the user globally, that is, from all applications
     * that uses this same user pool. It cancels the timer that checks for inactivity and
     * pings Cognito to refresh the user token; redirects the user to the login page; and
     * informs all other components in the application that the user was signed out by emitting
     * _user$.next(null).
     *
     * The from() function from RxJS just converts the Promise AWS Amplify returns into an Observable
     * and take(1) makes sure to run the subscription only once, avoiding possible memory leak or the need
     * to unscribe.
     */
    signOut() {
        from(this._auth.signOut({ global: true }))
            .pipe(take(1))
            .subscribe(() => {
            });
        this.stopTimer();
        this._cognitoUser = null;
        this._user$.next(null);

        this._router.navigateByUrl('/login');
    }

    /**
     * The forgot password method tells Cognito to send a code to the user's email.
     * This code will then be required by the login page and the user will be able to
     * enter a new password. This second part of resetting the page is forgotPasswordSubmit's function.
     *
     * The from() function from RxJS just converts the Promise AWS Amplify returns into an Observable
     * and take(1) makes sure to run the subscription only once, avoiding possible memory leak or the need
     * to unscribe.
     *
     * @param username
     */
    forgotPassword(username: string): Observable<any> {
        return from(this._auth.forgotPassword(username)).pipe(take(1));
    }

    /**
     *
     * The forgotPasswordSubmit method expects the code that was sent to the user's email, the username
     * and the new password that the user has set. If successful, the user will have created a new password
     * and will be required to log in with the new credentials.
     *
     * The from() function from RxJS just converts the Promise AWS Amplify returns into an Observable
     * and take(1) makes sure to run the subscription only once, avoiding possible memory leak or the need
     * to unscribe.
     *
     * @param username
     * @param code
     * @param password
     */
    forgotPasswordSubmit(username: string, code: string, password: string): Observable<any> {
        return from(this._auth.forgotPasswordSubmit(username, code, password)).pipe(take(1));
    }

    /**
     * This method contacts Cognito after the user has changed their temporary password that was
     * generated by Cognito and set a new password for that user. If successful, the user is logged in
     * correctly.
     *
     * The from() function from RxJS just converts the Promise AWS Amplify returns into an Observable
     * and take(1) makes sure to run the subscription only once, avoiding possible memory leak or the need
     * to unscribe.
     *
     * @param cognitoUser
     * @param newPassword
     */
    completeNewPasswordChallenge(cognitoUser: CognitoUser, newPassword: string): Observable<any> {
        // Sends new password information
        return from(this._auth.completeNewPassword(cognitoUser, newPassword, null)).pipe(
            take(1),
            switchMap(() => from(this._auth.currentAuthenticatedUser())),
            tap((user: CognitoUser) => {
                // Fetches the authenticated user and creates a new User object.
                this.createAndEmitUser(user);

                // Starts idle timer
                this.startTimer();
            })
        );
    }

    /**
     * Retrieves the user's Id Token that is used to authenticate
     * the backend calls. The authorization interceptor uses this
     * method to add the token to the calls' header.
     */
    getJwtToken() {
        if (!this._cognitoUser) {
            return null;
        }

        const signInUserSession = this._cognitoUser.getSignInUserSession();
        const idToken = signInUserSession ? signInUserSession.getIdToken() : null;

        if (!idToken || ((idToken.getExpiration() * 1000) <= Date.now())) {
            const refreshToken = signInUserSession.getRefreshToken();
            return new Promise((resolve) => {
                this._cognitoUser.refreshSession(refreshToken, (err, session) => {
                    if (err) {
                        resolve(this._auth.signOut());
                    }
                    this._cognitoUser.setSignInUserSession(session);
                    resolve(session.getIdToken().getJwtToken());
                })
            });
        }

        return Promise.resolve(idToken.getJwtToken());
    }

    /**
     * configureTimer configures the ng-idle extension that checks for user inactivity
     * and pings cognito to refresh the user token.
     */
    private configureTimer() {
        // this._idle.setIdle(1800); // User is considered idle after 30 minutes
        // this._idle.setTimeout(60); // After 31 minutes (1 minute after being idle), the user will be timed out
        // // Set interrupts that restart the timer, like mouse move, scroll, click, etc.
        // this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // Show a message to the user that says when they will be logged out due to inactivity.
        // const idleStartSub = this._idle.onIdleStart.subscribe(() => {
        //     // A custom snackBar is used instead of the notify service, because dynamic data needs
        //     // to be passed to the component. This can't be achieved with the regular snackBar, because
        //     // it only takes strings as argument.
        //     this._countdownRef = this._snackBar.openFromComponent(CountdownSnackbarComponent, {
        //         data: this._idle.onTimeoutWarning,
        //         verticalPosition: 'top',
        //     });
        // });

        // // If the user stops being idle, close the timeout message
        // const idleEndSub = this._idle.onIdleEnd.subscribe(() => {
        //     // This method is run inside the ngZone to force a template update and thus close the snackBar.
        //     // Otherwise, the snackBar would only close next time there was an interaction with the template,
        //     // for example a user click on a button. This is probably because the the idle service runs outside
        //     // the NgZone.
        //     this._ngZone.run(() => {
        //         this._countdownRef.dismissWithAction();
        //     });
        // });

        // // Log the user out when they are timeouted.
        // const timeoutSub = this._idle.onTimeout.subscribe(() => {
        //     this._notify.warning(
        //         this._translate.instant('CORE.MSG_YOU_HAVE_BEEN_LOGGED_OUT_DUE_TO_INACTIVITY')
        //     );
        //     this.signOut();
        // });

        // Ping Cognito every 5 minutes to refresh the token
        // TODO check for a more efficient way to refresh the token.
        // This should only be worked and looked upon if you are having real users in the system that are also experiencing
        // issues with being logged out from the system while working in it. We have tried to fix this in different interations but
        // it has been rally hard for us to get this working, now you always gets logged out after ca 1h logged in to the system active or not.
        // this._keepalive.interval(300);
        // const keepaliveSub = this._keepalive.onPing.subscribe(() => {
        //     this.refreshToken()
        //         .pipe(take(1))
        //         .subscribe();
        // });

        // Save subscriptions to an array to unsubscribe them when the service is destroyed.
        // this._idleSubs.push(timeoutSub, keepaliveSub, idleStartSub, idleEndSub);
    }

    /**
     * This function makes sure the user is authenticated while still logged in.
     * To refresh its tokens, first the system needs to fetch the currentSession and
     * then the currentAuthenticatedUser, only after this the tokens are refreshed.
     *
     * This method will fail when the user's Refresh Token is older than 30 days, that is,
     * the user hasn't logged in 30 days. The Id Token and Access Token on their turn expire
     * within 1 hour and need to be constantly refreshed. This function is called by both the
     * service's constructor and the keepalive extension, that pings cognito every 30 minutes.
     *
     * The from() function converts a Promise into an Observable and the switchMap operator
     * substitutes the source observable (the from(currentSession()) function) for the returned
     * observable from(currentAuthenticatedUser()). So that when this observable is subscribed to
     * a CognitoUser is returned instead of the CognitoUserSession the first observable returns.
     * We also keep track of the cognitoUser since it's a quicker way to retrieve the Id Token
     * used in the authentication.
     */
    private refreshToken(): Observable<any> {
        return from(this._auth.currentSession()).pipe(
            switchMap(
                session => from(this._auth.currentAuthenticatedUser()) as Observable<CognitoUser>
            ),
            tap(user => (this._cognitoUser = user))
        );
    }

    private startTimer() {
        // if (this._idle.isRunning()) {
        //     return;
        // }
        // // Start timer
        // this._idle.watch();
    }

    private stopTimer() {
        // if (this._idle.isRunning()) {
        //     this._idle.stop();
        // }
    }

    /**
     * This method creates our own user object based on the cognito user
     * that is fetched after a successful authentication. It is then emitted
     * as the next value for the Subject _user$, which is listened by many
     * components throughout the application to check if the user is logged in or not.
     * @param cognitoUser
     */
    private createAndEmitUser(cognitoUser: any) {
        this._cognitoUser = cognitoUser;
        const attributes = cognitoUser['attributes'];
        const user = new User(
            attributes.email,
            attributes.name,
            attributes.phone_number,
            cognitoUser.signInUserSession.accessToken.payload["cognito:groups"].includes("admin"),
            cognitoUser.signInUserSession.accessToken.payload["cognito:groups"],
            "",
            attributes["custom:customer"],
            attributes["custom:customer_name"]);
        this._user$.next(user);
    }
}
