export class User {
	constructor(
		public email: string,
		public name: string,
		public phone_number: string,
		public system_admin: boolean,
		public groups: string[],
		public id?: string,
		public customer_id?: number,
		public customer_name?: string,
	) { }

	public static getEmptyUser(): User {
		return new User('', '', '', false, null, null, null);
	}
}
