import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthorizationService } from './modules/services/authorization.service';
import { User } from './modules/models/user.model';
import { UserService } from './modules/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  userSub: Subscription;

  constructor(private auth: AuthorizationService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.userSub = this.auth.user$.subscribe(user => {
      this.user = user;
      if (user != null)
        this.loadData();
    }
    );

  }

  loadData() {
    // this.userService.getUserMenu()
    //   .subscribe((menu: any) => {
    //     this.menuItems = menu
    //   })
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    sessionStorage.clear()
  }

  menuItems = [{ label: 'Orders', path: '/orders', disabled: false, querystring: {} }];
  title = 'swag';
}
