import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CoreRoutingModule } from './core-routing.module';

import { AngularMaterialModule } from 'src/app/angular-material.module';


@NgModule({
  declarations: [LoginComponent, DashboardComponent, ToolbarComponent],
  imports: [
    AngularMaterialModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CoreRoutingModule,
  ],
  exports: [ToolbarComponent],
})
export class CoreModule { }
