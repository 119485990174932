import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'swag-dialog-info',
	templateUrl: './dialog-info.component.html',
	styleUrls: ['./dialog-info.component.css'],
})
export class DialogInfoComponent {
	constructor(
		public dialogRef: MatDialogRef<DialogInfoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	cancel(): void {
		this.dialogRef.close();
	}
}
