<div fxLayout="column" fxFlex="100" style="background-color: {{ color }}; color: white">
	<div fxLayout="row" style="height:70px; ">
		<div class="title" fxLayout="center center" routerLink="/">
			<img src="../../../../assets/logo_npb.png" width="250" height="55" >
		</div>
		<div *ngIf="user" fxLayout="row" fxFlex="1000" fxLayoutAlign="end center">
			<ng-container *ngTemplateOutlet="menubar"></ng-container>
		</div>
		<div fxFlex></div>
		<div *ngIf="user" fxLayout="row" fxLayoutAlign="end center">
			<ng-container *ngTemplateOutlet="menubar_buttons"></ng-container>
		</div>
		<div *ngIf="user && (user.email == 'luizmpf@gmail.com' || user.email == 'luizmpf.psn@gmail.com')" fxLayout="row" fxFlex="50px" fxLayoutAlign="end start">
			<ng-container *ngTemplateOutlet="detailsbar"></ng-container>
		</div>
		<div *ngIf="!user && false" fxLayout="row" fxFlex="100" fxLayoutAlign="end start">
			<div style="border-left: 1px white solid; text-align: center; padding-top: 5px; width: 50px;">
				<ng-container *ngTemplateOutlet="loadinglogo"></ng-container>
			</div>
		</div>
	</div>
	<mat-progress-bar color="primary" fxFlex="1 0 auto" [mode]="mode" [value]="value" [bufferValue]="value"></mat-progress-bar>
</div>
<div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing" *ngIf="value!=100"></div>
<div class="loader" *ngIf="value!=100"></div>

<ng-template #menubar>
	<div *ngIf="orderMenu.length > 0" fxFlex="150px" class="menu">
		<button mat-button [matMenuTriggerFor]="menuOrder">
			Orders
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<div *ngIf="productMenu.length > 0" fxFlex="150px" class="menu">
		<button mat-button [matMenuTriggerFor]="menuProduct">
			Products
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<div *ngIf="customerMenu.length > 0" fxFlex="150px" class="menu">
		<button mat-button [matMenuTriggerFor]="menuCustomer">
			Customers
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<div *ngIf="purchaseMenu.length > 0" fxFlex="150px" class="menu">
		<button mat-button [matMenuTriggerFor]="menuPurchase">
			Purchase
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<div *ngIf="wmsMenu.length > 0" fxFlex="150px" class="menu">
		<button mat-button [matMenuTriggerFor]="menuWMS">
			WMS
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<div *ngIf="adminMenu.length > 0" fxFlex="150px" class="menu">
		<button mat-button [matMenuTriggerFor]="menuAdmin">
			Admin
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<div *ngIf="newOrderMenu.length > 0" fxFlex="170px" class="menu menu-button">
		<button mat-button [matMenuTriggerFor]="menuNewOrder" style="color: gray">
			Create Order
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
	</div>
	<mat-menu #menuOrder="matMenu">
		<ng-container *ngFor="let menu of orderMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route" [queryParams]="menu.querystring">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuProduct="matMenu">
		<ng-container *ngFor="let menu of productMenu">
			<button *ngIf="menu.title != 'divider' && menu.title != 'Price & Cogs'" mat-menu-item [routerLink]="menu.route">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
      <button *ngIf="menu.title == 'Price & Cogs'" mat-menu-item [matMenuTriggerFor]="menuProductPrices">
				<span class="menu-item">Price & Cogs</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuProductPrices="matMenu">
		<ng-container *ngFor="let menu of customerPricesMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route" [queryParams]="menu.querystring">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuCustomer="matMenu">
		<ng-container *ngFor="let menu of customerMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route" [queryParams]="menu.querystring">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuAdmin="matMenu">
		<ng-container *ngFor="let menu of adminMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuPurchase="matMenu">
		<ng-container *ngFor="let menu of purchaseMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuWMS="matMenu">
		<ng-container *ngFor="let menu of wmsMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
	<mat-menu #menuNewOrder="matMenu">
		<ng-container *ngFor="let menu of newOrderMenu">
			<button *ngIf="menu.title != 'divider'" mat-menu-item [routerLink]="menu.route">
				<span class="menu-item">{{ menu.title }}</span>
			</button>
			<mat-divider *ngIf="menu.title == 'divider'"></mat-divider>
		</ng-container>
	</mat-menu>
</ng-template>

<ng-template #menubar_buttons>
	<ng-container *ngFor="let menu of pageMenu">
		<div *ngIf="adminMenu.length > 0" fxFlex="150px" class="menu menu-button">
			<button mat-button [routerLink]="menu.route" class="menu-button">
				<b>{{ menu.title }}</b>
			</button>
		</div>
	</ng-container>
</ng-template>

<ng-template #detailsbar>
	<div style="text-align: center; padding-top: 15px; width: 50px;">
		<button mat-icon-button [matMenuTriggerFor]="menuAccount">
			<ng-container *ngTemplateOutlet="loadinglogo"></ng-container>
		</button>
	</div>
	<mat-menu #menuAccount="matMenu">
		<button mat-menu-item disabled>
			<mat-icon>person</mat-icon>
			<span>{{ user.name }} ({{ user.email }})</span>
		</button>
		<button mat-menu-item disabled>
			<mat-icon>groups</mat-icon>
			<span>{{ groupName() }}</span>
		</button>
		<mat-divider></mat-divider>
		<button mat-menu-item disabled *ngIf="!user.system_admin">
			<mat-icon>store</mat-icon>
			<span>{{ user.customer_name }}</span>
		</button>
		<button mat-menu-item *ngIf="user.system_admin" routerLink="/errorlog">
			<mat-icon>bug_report</mat-icon>
			<span>Error Log</span>
		</button>
		<mat-divider></mat-divider>
		<button mat-menu-item (click)="onSignOut()" >
			<mat-icon>power_settings_new</mat-icon>
			<span>Sign Out</span>
		</button>
	</mat-menu>
</ng-template>

<ng-template #loadinglogo>
	<div *ngIf="value!=100" style="cursor: pointer">
		<img class="spinning-logo  spin" src="../../../assets/spinning_logo_npb.png" height="40px"/>
	</div>
	<div *ngIf="value==100" style="cursor: pointer">
		<img class="spinning-logo" src="../../../assets/spinning_logo_npb.png" height="40px"/>
	</div>
</ng-template>
