import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Subscription } from 'rxjs';


import { AuthorizationService } from '../../services/authorization.service';
import { SpinnerService } from '../../services/spinner.service';
import { User } from '../../models/user.model';
import { environment } from 'src/environments/environment';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'swag-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  mode: ProgressBarMode = 'determinate';
  value = 100;
  user: User;
  subscription: Subscription;
  color = environment.COLOR;

  constructor(public auth: AuthorizationService, private spinnerService: SpinnerService,
    public userService: UserService) { }

  orderMenu: any[] = []
  productMenu: any[] = []
  customerPricesMenu: any[] = []
  customerMenu: any[] = []
  adminMenu: any[] = []
  purchaseMenu: any[] = []
  wmsMenu: any[] = []
  pageMenu: any[] = []
  newOrderMenu: any[] = []

  ngOnInit() {
    this.subscription = this.auth.user$.subscribe(user => {
      (this.user = user)
      if (user != null)
        this.loadData();
    });

    this.spinnerService.loading$.subscribe(status => {
      this.mode = status.mode;
      this.value = status.value;
    });
  }

  loadData() {
    let menu = sessionStorage.getItem("menu")
    if (menu != null) {
      this.buildMenu(JSON.parse(menu))
    }
    else {
      this.userService.getUserMenu()
        .subscribe((menu: any) => {
          sessionStorage.setItem("menu", JSON.stringify(menu))
          this.buildMenu(menu)
        })
    }
  }

  buildMenu(menu: any) {
    this.orderMenu = menu["order"]
    this.productMenu = menu["product"]
    this.customerPricesMenu = menu["customerPrices"]
    this.customerMenu = menu["customer"]
    this.adminMenu = menu["admin"]
    this.purchaseMenu = menu["purchase"]
    this.wmsMenu = menu["wms"]
    this.pageMenu = menu["page"]
    this.newOrderMenu = menu["new_order"]
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSignOut(): void {

    sessionStorage.clear()
    this.auth.signOut();
  }

  groupName() {
    return this.user.groups[0].replace(/^./, this.user.groups[0][0].toUpperCase())

  }
}
