import { Injectable } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SpinnerService {
	private _loading$ = new Subject<{
		isLoading: boolean;
		mode: ProgressBarMode;
		value: number;
	}>();

	public loading$ = this._loading$.asObservable();

	private currentStatus = false;

	constructor() { }

	isLoading(status: boolean): void {
		// Keep same state in case the new status is the same as before.
		if (this.currentStatus === status) {
			return;
		}

		this.currentStatus = status;
		switch (this.currentStatus) {
			case true: {
				this._loading$.next({
					isLoading: true,
					mode: 'indeterminate',
					value: 0
				});
				break;
			}
			case false: {
				this._loading$.next({
					isLoading: false,
					mode: 'determinate',
					value: 100
				});
				break;
			}
		}
	}

	buffer(value = 0): void {
		// Keep same state in case the new status is the same as before.

		if (value == 100)
			return this.isLoading(false)

		this._loading$.next({
			isLoading: true,
			mode: 'buffer',
			value: value
		});

	}
}
