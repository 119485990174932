import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { from, Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen, switchMap } from 'rxjs/operators';

import { AuthorizationService } from '../services/authorization.service';
import { NotificationService } from '../services/notification.service';
import { SpinnerService } from '../services/spinner.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(
        private authorizationService: AuthorizationService,
        private notify: NotificationService,
        private spinner: SpinnerService
    ) { }

    // Retrieved from https://www.learnrxjs.io/operators/error_handling/retrywhen.html
    // EXAMPLE 2: CUSTOMIZABLE RETRY WITH INCREASED DURATION
    genericRetryStrategy = ({
        maxRetryAttempts = 5,
        scalingDuration = 1000,
        acceptedStatusCodes = [504],
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        acceptedStatusCodes?: number[];
    } = {}) => (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    retryAttempt > maxRetryAttempts ||
                    !acceptedStatusCodes.find(e => e === error.status)
                ) {
                    return throwError(error);
                }

                // TODO add translation
                this.notify.warning(
                    `Error loading page or resource. Retrying in: ${retryAttempt} s`
                );

                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            })
        );
    };



    // The HttpInterceptor only intercepts http requests made using Angular's HttpClient.
    // This means that AWS Amplify requests aren't handled by this method.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.authorizationService.getJwtToken())
            .pipe(switchMap((token: any) => {
                if (token != null) {
                    req = req.clone({
                        headers: req.headers.set('Authorization', token),
                    });
                }

                return next.handle(req)
            }))
    }

    errorHandler = (error: HttpErrorResponse) => {
        this.spinner.isLoading(false);
        this.notify.warning(error.error.message || error.message);
    };
}
