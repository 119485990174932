<div fxFlex fxLayout="row" fxLayoutAlign="center baseline">
	<div fxLayout="column">
	<mat-card fxLayout="column" class="login-card" style="width: 300px;">
		<div *ngIf="mode === 'login'" class="header"fxLayout="row" fxLayoutAlign="center baseline">Login</div>
		<div *ngIf="mode === 'forgot_password'" class="header" fxLayout="row" fxLayoutAlign="center baseline">Forgot Password</div>
		<div *ngIf="mode === 'code_sent'" class="header" fxLayout="row" fxLayoutAlign="center baseline">Enter Code and New Password</div>
		<div *ngIf="mode === 'challenge'" class="header" fxLayout="row" fxLayoutAlign="center baseline">New Password</div>
		<form
			(ngSubmit)="onSubmit(form)"
			#form="ngForm"
			fxLayout="column"
			fxLayoutAlign="space-between stretch"
			fxLayoutGap="2.5%"
		>
			<!-- Email -->
			<mat-form-field>
				<input
					matInput
					placeholder="Email"
					[(ngModel)]="model.username"
					#email="ngModel"
					name="username"
					required
					email
					[disabled]="mode === 'challenge' || mode === 'code_sent'"
				/>
			</mat-form-field>

			<!-- Password -->
			<mat-form-field *ngIf="mode === 'login'">
				<input
					matInput
					placeholder="Password"
					[(ngModel)]="model.password"
					type="password"
					name="password"
					required
					minlength="8"
				/>
			</mat-form-field>

			<!-- Code -->
			<mat-form-field *ngIf="mode === 'code_sent'">
				<input
					matInput
					type="text"
					placeholder="Code"
					[(ngModel)]="model.code"
					name="code"
					required
				/>
			</mat-form-field>

			<!-- New password -->
			<mat-form-field *ngIf="mode === 'challenge' || mode === 'code_sent'">
				<input
					matInput
					placeholder="New Password"
					[(ngModel)]="model.newPassword"
					type="password"
					name="newPassword"
					required
					minlength="8"
				/>
			</mat-form-field>

			<!-- Confirm new password -->
			<mat-form-field *ngIf="mode === 'challenge' || mode === 'code_sent'">
				<input
					matInput
					placeholder="Confirm New Password"
					[(ngModel)]="model.confirmNewPassword"
					#confirm="ngModel"
					type="password"
					name="confirmNewPassword"
					required
					minlength="8"
				/>
			</mat-form-field>

			<!-- Submit button -->
			<button
				mat-raised-button
				type="submit"
				color="primary"
				[disabled]="isLoading || !form.valid"
			>
				{{ getSubmitButtonText()  }}
			</button>

			<!-- Forgot password button -->
			<!-- TODO add translation -->
			<button
				mat-button
				type="button"
				*ngIf="mode === 'login'"
				(click)="onForgotPassword()"
				[disabled]="isLoading"
			>
				Forgot Password
			</button>

			<!-- Back to login button -->
			<!-- TODO add translation -->
			<button
				mat-button
				type="button"
				*ngIf="mode !== 'login'"
				(click)="onGoBackToLogin()"
				[disabled]="isLoading"
			>
				Go back to Login
			</button>
		</form>
	</mat-card>
</div>
</div>