import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CognitoUser } from '@aws-amplify/auth';
import { NgForm } from '@angular/forms';

import { AuthorizationService } from '../../services/authorization.service';
import { SpinnerService } from '../../services/spinner.service';
import { NotificationService } from '../../services/notification.service';

enum Mode {
  LOGIN = 'login',
  CHALLENGE = 'challenge',
  FORGOT_PASSWORD = 'forgot_password',
  CODE_SENT = 'code_sent',
}

@Component({
  selector: 'swag-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private auth: AuthorizationService,
    private _router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
  ) { }

  model: {
    username: string;
    password: string;
    code: string;
    newPassword: string;
    confirmNewPassword: string;
  };
  isLoading: boolean = false;
  mode: Mode = Mode.LOGIN;
  cognitoUser: CognitoUser;
  loadingSubscription: Subscription;
  userSubscription: Subscription;
  returnUrl: string = "";
  @ViewChild('form', { static: true }) form: NgForm;

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnTo'] || '/';
    this.userSubscription = this.auth.user$.subscribe(user => {
      if (!!user) this._router.navigateByUrl(this.returnUrl);
    });

    this.loadingSubscription = this.spinnerService.loading$.subscribe(
      status => (this.isLoading = status.isLoading)
    );

    this.model = {
      username: '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
      code: '',
    };
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  onForgotPassword() {
    this.form.resetForm({ username: this.model.username });
    this.mode = Mode.FORGOT_PASSWORD;
  }

  onGoBackToLogin() {
    this.form.resetForm({ username: this.model.username });
    this.mode = Mode.LOGIN;
  }

  sendForgotPasswordCode() {
    if (!this.model.username) {
      this.notificationService.warning('CORE.EMAIL_IS_MISSING');
      return;
    }

    this.spinnerService.isLoading(true);
    this.auth
      .forgotPassword(this.model.username)
      .subscribe(
        () => {
          this.mode = Mode.CODE_SENT;
          this.form.resetForm({ username: this.model.username });
          this.notificationService.warning('CORE.A_CODE_WAS_SENT_TO_USERNAME');
        },
        error => {
          console.error(error);
          this.notificationService.warning(error.message);
        }
      )
      .add(() => this.spinnerService.isLoading(false));
  }

  submitForgotPassword() {
    if (this.model.newPassword !== this.model.confirmNewPassword) {
      this.notificationService.warning('CORE.MSG_PASSWORDS_DO_NOT_MATCH');
      return;
    }

    if (!this.model.code) {
      this.notificationService.warning('CORE.MSG_CODE_IS_MISSING');
      return;
    }

    this.spinnerService.isLoading(true);
    this.auth
      .forgotPasswordSubmit(
        this.model.username,
        this.model.code,
        this.model.confirmNewPassword
      )
      .subscribe(
        () => {
          this.notificationService.open('CORE.MSG_PASSWORD_WAS_CHANGED_SUCCESSFULLY', 'CORE.DISMISS');
          this.mode = Mode.LOGIN;
          this.form.resetForm({ username: this.model.username });
        },
        () =>
          this.notificationService.warning('CORE.MSG_CODE_IS_INVALID')
      )
      .add(() => this.spinnerService.isLoading(false));
  }

  changePassword() {
    this.spinnerService.isLoading(true);
    this.auth
      .completeNewPasswordChallenge(this.cognitoUser, this.model.newPassword)
      .subscribe(
        () => {
          this._router.navigateByUrl(this.returnUrl);
        },
        err => {
          this.notificationService.warning(err.message);
          console.error(err);
        }
      )
      .add(() => this.spinnerService.isLoading(false));
  }

  logIn() {
    if (!this.model.username || !this.model.password) {
      return;
    }
    this.model.username = this.model.username.trim();
    this.spinnerService.isLoading(true);
    this.auth
      .signIn(this.model.username, this.model.password)
      .subscribe(
        user => {
          if (
            user['challengeName'] &&
            user['challengeName'] === 'NEW_PASSWORD_REQUIRED'
          ) {
            this.mode = Mode.CHALLENGE;
            this.form.resetForm({ username: this.model.username });
            this.cognitoUser = user;
          } else {
            this._router.navigateByUrl(this.returnUrl);
          }
        },
        e => {
          console.error(e);
          this.form.resetForm({ username: this.model.username });
          this.notificationService.open(e.message, 'Dismiss');
        }
      )
      .add(() => this.spinnerService.isLoading(false));
  }

  getSubmitButtonText(): string {
    switch (this.mode) {
      case Mode.LOGIN:
        return 'Log In';
      case Mode.CHALLENGE:
        return 'Change Password';
      case Mode.FORGOT_PASSWORD:
        return 'Submit';
      case Mode.CODE_SENT:
        return 'Reset Password';
    }
  }

  onSubmit(form: NgForm) {
    switch (this.mode) {
      case Mode.LOGIN:
        this.logIn();
        break;
      case Mode.CHALLENGE:
        this.changePassword();
        break;
      case Mode.FORGOT_PASSWORD:
        this.sendForgotPasswordCode();
        break;
      case Mode.CODE_SENT:
        this.submitForgotPassword();
        break;
    }
  }
}
