import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthorizationGuard } from '../guards/authorization.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
	{ path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthorizationGuard],
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CoreRoutingModule { }
