<h1 mat-dialog-title style="width:500">{{ data.header }}</h1>
<div mat-dialog-content>
	{{ data.description }}
	<ul *ngFor="let item of data.list">
		<li *ngIf="item.value != '' && item.value != null">
			<div class="label">{{ item.key }}</div>
			<div class="value">{{ item.value }}</div>
		</li>
	</ul>
	<br><br>
</div>
<div class="button-row" fxLayout="row" fxLayoutAlign="end">
	<button mat-raised-button (click)="cancel()" tabindex="-1" color="primary">{{ data.button_text }}</button>
</div>
