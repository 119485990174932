import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/modules/models/pagination.model';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly API_URL = environment.USER_URL;

  constructor(private httpClient: HttpClient) { }

  /** CRUD METHODS */
  getAllUsers(searchField: string, pageSize: number, currentPage: number): Observable<Pagination> {
    return this.httpClient.get<Pagination>(this.API_URL + `?searchField=${searchField}&pagesize=${pageSize}&currentpage=${currentPage + 1}`)
  }

  getAllGroups(): Observable<Pagination> {
    return this.httpClient.get<Pagination>(this.API_URL + '/groups')
  }

  saveUser(user: User) {
    return this.httpClient.put(this.API_URL, user);
  }

  resendUser(user: User) {
    return this.httpClient.put(this.API_URL + '/resend', user);
  }

  updateUser(user: User): void {
    return null
    // this.dialogData = user;
  }

  deleteUser(id: string) {
    return this.httpClient.delete(this.API_URL + id);
  }

  getUserMenu() {
    return this.httpClient.get<any>(this.API_URL + '/menu')
  }
}