import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AmplifyService, AmplifyModules, AmplifyAngularModule } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';

import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './angular-material.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizationInterceptor } from './modules/interceptors/authorization.interceptor';

import { CoreModule } from './modules/components/core.module';
import { DialogInfoComponent } from 'src/app/modules/components/shared/dialog-info/dialog-info.component';
import { DialogConfirmComponent } from './modules/components/shared/dialog-confirm/dialog-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    DialogInfoComponent,
    DialogConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    AmplifyAngularModule,
    CoreModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
        });
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogInfoComponent, DialogConfirmComponent]
})
export class AppModule { }
