import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'swag-dialog-confirm',
	templateUrl: './dialog-confirm.component.html',
	styleUrls: ['./dialog-confirm.component.css'],
})
export class DialogConfirmComponent {
	constructor(
		public dialogRef: MatDialogRef<DialogConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			header: string;
			text: string;
			info: string;
			question: string;
			ok_label: string;
			cancel_label: string;
		}
	) { }

	cancel(): void {
		this.dialogRef.close();
	}

	confirm(): void {
		this.dialogRef.close(1);
	}
}
