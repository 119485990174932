import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './modules/components/dashboard/dashboard.component';
import { LoginComponent } from './modules/components/login/login.component';
import { AuthorizationGuard } from './modules/guards/authorization.guard';

const appRoutes: Routes = [
  {
    path: '',
    //redirectTo: '',
    component: DashboardComponent,
    canActivate: [AuthorizationGuard],
    pathMatch: 'full',
  },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
  {
    path: 'products',
    loadChildren: () => import('./modules/components/products/products.module').then(mod => mod.ProductsModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/components/orders/orders.module').then(mod => mod.OrdersModule),
  },
  {
    path: 'wms',
    loadChildren: () => import('./modules/components/wms/wms.module').then(mod => mod.WMSModule),
  },
  {
    path: 'apikey',
    loadChildren: () => import('./modules/components/apikey/apikey.module').then(mod => mod.ApiKeyModule),
  },
  {
    path: 'errorlog',
    loadChildren: () => import('./modules/components/error-log/error-log.module').then(mod => mod.ErrorLogModule),
  },
  {
    path: 'retailers',
    loadChildren: () => import('./modules/components/customers/customers.module').then(mod => mod.CustomersModule),
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./modules/components/campaigns/campaign.module').then(mod => mod.CampaignModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/components/user/user.module').then(mod => mod.UserModule),
  },
  {
    path: 'tags',
    loadChildren: () => import('./modules/components/tags/tags.module').then(mod => mod.TagModule),
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,
    { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
