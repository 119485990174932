// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL: 'https://devrest.swaggintails.com/apis',
  CAMPAIGN_URL: 'https://devrest.swaggintails.com/campaigns',
  CUSTOMER_URL: 'https://devrest.swaggintails.com/customers',
  ERRORLOG_URL: 'https://devrest.swaggintails.com/errorlogs',
  GROUPING_URL: 'https://devrest.swaggintails.com/groups',
  INVENTORY_URL: 'https://devrest.swaggintails.com/inventories',
  ORDER_URL: 'https://devrest.swaggintails.com/orders',
  // ORDER_WOOCOM_URL: 'https://devrest.swaggintails.com/campaigns',
  // ORDER_PRITAX_URL: 'https://devrest.swaggintails.com/campaigns',
  PRICELIST_URL: 'https://devrest.swaggintails.com/pricelists',
  PRODUCT_DATA_CUSTOMER: 'https://devrest.swaggintails.com/products-data',
  USER_URL: 'https://devrest.swaggintails.com/users',
  INTEGRATION_URL: 'https://devrest.swaggintails.com/integrations',
  PACKAGE_URL: 'https://devrest.swaggintails.com/packages',
  TAG_URL: 'https://devrest.swaggintails.com/tags',


  // API_URL: 'https://mgxkw1s5sk.execute-api.eu-west-1.amazonaws.com/dev',
  // CAMPAIGN_URL: 'https://18zucbgx9b.execute-api.eu-west-1.amazonaws.com/dev',
  // CUSTOMER_URL: 'https://9q5gfcsew2.execute-api.eu-west-1.amazonaws.com/dev',
  // ERRORLOG_URL: 'https://bg4lmhx7xe.execute-api.eu-west-1.amazonaws.com/dev',
  // GROUPING_URL: 'https://9jo0qqdxu3.execute-api.eu-west-1.amazonaws.com/dev',
  // INVENTORY_URL: 'https://51vftms3vg.execute-api.eu-west-1.amazonaws.com/dev',
  // ORDER_URL: 'https://2fx8eyblqe.execute-api.eu-west-1.amazonaws.com/dev',
  // ORDER_WOOCOM_URL: 'https://9bdez0yel3.execute-api.eu-west-1.amazonaws.com/dev',
  // ORDER_PRITAX_URL: 'https://ay6dcs1fu7.execute-api.eu-west-1.amazonaws.com/dev',
  // PRICELIST_URL: 'https://9fjhr7op71.execute-api.eu-west-1.amazonaws.com/dev',
  // PRODUCT_DATA_CUSTOMER: 'https://b2at3dsnvd.execute-api.eu-west-1.amazonaws.com/dev',
  // USER_URL: 'https://drwe0n4ox9.execute-api.eu-west-1.amazonaws.com/dev',
  // INTEGRATION_URL: 'https://ynuy1lm8pj.execute-api.eu-west-1.amazonaws.com/dev',
  // PACKAGE_URL: 'https://wnsgi18un2.execute-api.eu-west-1.amazonaws.com/dev',
  // TAG_URL: 'https://dh73l8swhg.execute-api.eu-west-1.amazonaws.com/dev',


  COLOR: '#66BBFF',
  APIKEY_VALUE: 'tp8VIMKsI6aXKP9wzoAAv5WMr3R6GX65q6KPhq10',
  // PS_URL: 'https://devportal.swaggintails.com/ps/dev/'
  // UserPoolId: '__USERPOOL_ID__',
  // ClientId: '__CLIENT_ID__',
  // IdentityPoolId: '__IDENTITYPOOL_ID__',
  // Stage: '__STAGE__',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
